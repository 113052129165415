import http from '../http'

// 向外暴露封装的请求结果

export const Login = (data) => {
        // 以返回值的形式将请求结果返回出去
        return http({
            url: '/web/user/login',
            method: 'post',
            data
        })

    }
    //搭建商注册
export const register = (data) => {
        return http({
            url: '/web/user/register',
            method: 'post',
            data
        })
    }
    //获取验证码
export const getCode = (phone) => {
    return http({
        url: '/web/user/sendSms',
        method: 'get',
        params: {
            phone
        }
    })
}

//查询展会列表
export const getExhibition = () => {
    return http({
        url: '/web/exhibition/getList',
        method: 'get'
    })
}

//用户登录
export const login = (data) => {
    return http({
        url: '/web/user/login',
        method: 'post',
        data
    })
}

//判断展商有没有参与本场展会 传入展会id
export const exhibitionAuth = (id) => {
    return http({
        url: '/web/exhibition/checkExhibition',
        method: 'get',
        params: {
            id
        }
    })
}

//根据位置下载文件
export const getFileByType = (params) => {
    return http({
        url: '/web/file/getListByType',
        method: 'get',
        params
    })
}

// 下载中心
export const fileList = () => {
    return http({
        url: '/web/file/getList',
        method: 'get',
        params: {
            type: 5
        }
    })
}

//删除子项
export const deleteItem = (id) => {
    return http({
        url: `/web/exOrder/info/delete`,
        method: 'get',
        params: {
            id
        }
    })
}

//删除设施申报子项
export const deleteSheItem = (id) => {
    return http({
        url: '/web/exOrder/facilities/delete',
        method: 'get',
        params: {
            id
        }
    })
}